<template>
  <div>
    <!-- =======  MAIN ======= -->
    <main id="main">
      <!-- ======= BAŞLIK ======= -->
      <section class="intro-single">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-lg-8">
              <div class="title-single-box">
                <h1 class="title-single">Kent Rehberi</h1>
                <span class="color-text-b">Gezilecek Yerler</span>
              </div>
            </div>
            <div class="col-md-12 col-lg-4">
              <nav
                aria-label="breadcrumb"
                class="breadcrumb-box d-flex justify-content-lg-end"
              >
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="/">Anasayfa</a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Kent Rehberi
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <!-- ======= BAŞLIK ======= -->

      <!-- =======  REHBER ======= -->
      <section class="news-grid grid">
        <div class="container">
          <div class="row">
            <div class="col-lg-9">
              <div class="row">
                <div
                  v-for="(yer, index) in Yerler.slice(0, 12)"
                  :key="index"
                  class="col-md-4"
                >
                  <div class="card-box-b card-shadow news-box">
                    <div style="height: 320px" class="img-box-b">
                      <img
                        style="object-fit: cover; height: 420px"
                        :src="yer.kapak"
                        alt=""
                        class="img-b img-fluid"
                      />
                    </div>
                    <div class="card-overlay">
                      <div class="card-header-b">
                        <div class="card-category-b">
                          <a @click="detayagit(yer.yerId)" class="category-b">{{
                            yer.yerTarihi.split(" ")[1]
                          }}</a>
                        </div>
                        <div class="card-title-b">
                          <h2 class="title-2">
                            <a @click="detayagit(yer.yerId)"
                              >{{ yer.yerAdi }}
                            </a>
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Sidebar />
          </div>
          <div class="row">
            <div class="col-sm-9">
              <nav class="pagination-a">
                <ul class="pagination justify-content-end">
                  <li class="page-item disabled">
                    <a class="page-link" href="#" tabindex="-1">
                      <span class="bi bi-chevron-left"></span>
                    </a>
                  </li>
                  <li class="page-item active">
                    <a class="page-link" href="#">1</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">2</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">3</a>
                  </li>
                  <li class="page-item next">
                    <a class="page-link" href="#">
                      <span class="bi bi-chevron-right"></span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <!-- =======  REHBER ======= -->
    </main>
    <!-- =======  MAIN ======= -->
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
export default {
  name: "Yerler",
  data() {
    return {
      Yerler: [],
    };
  },
  async created() {
    const domainAd = window.location.hostname;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.$store.dispatch("vericekme", "Yerler");
    const yerlist = this.$store.getters.getYerler;
    this.Yerler = Object.values(yerlist);
    this.Yerler.sort(function (b, a) {
      return a.yerId - b.yerId;
    });
  },
  methods: {
    detayagit(ID) {
      this.$router.push("/KentDetay/" + ID);
    },
  },
  components: {
    Sidebar,
  },
};
</script>

<style>
</style>